<template>
  <div>
    <Pane />
    <Contract v-if="type === 'contract'" :list="list" />
    <a-card class="container" v-if="error">
      <a-result status="500" title="分享已过期"> </a-result>
    </a-card>
  </div>
</template>

<script>
import { fetchData } from "@/api/share";
import Contract from "./components/contract.vue";
export default {
  components: {
    Contract,
  },

  data() {
    return {
      type: "",
      list: [], // id list
      error: false,
    };
  },
  mounted() {
    const { query } = this.$route;
    const { id } = query || {};
    fetchData({
      id,
    })
      .then((res) => {
        this.detail = res;
        if (res.body) {
          try {
            this.type = res.type;
            this.list = JSON.parse(res.body);
          } catch (error) {
            this.$message.error("数据格式出错");
          }
        }
      })
      .catch(() => {
        this.error = true;
      });
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}
</style>