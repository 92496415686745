<template>
  <a-card class="container" :loading="loading">
    <div v-for="(item, index) in contractList" :key="item.id" style="margin-bottom: 64px">
      <div class="between header">
        <span>
          {{ item.name }}
        </span>
        <span> {{ index + 1 }} / {{ contractList.length }} </span>
      </div>
      <a-descriptions bordered size="small" :column="2" style="margin-bottom: 12px">
        <a-descriptions-item>
          <div slot="label" class="center">设计编号</div>
          <div>
            {{ item.code }}
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">合同名称</div>
          <div>
            {{ item.name }}
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">合同金额(万元)</div>
          <div>
            <Money :money="item.contractAmount" />
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">所属区域</div>
          <div>
            <span>
              {{ item.areaName }}
            </span>
            <span v-if="item.subAreaName"> /{{ item.subAreaName }} </span>
            <span v-if="item.l3AreaName"> /{{ item.l3AreaName }} </span>
          </div>
        </a-descriptions-item>
        <a-descriptions-item>
          <div slot="label" class="center">责任部门</div>
          <div>
            {{ item.productionUnitName }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">生产负责人</div>
          <div>
            {{ item.productionMasterName }}
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">合同类型</div>
          <div>
            <DataDictFinder dictType="contractType" :dictValue="item.type" />
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">合同状态</div>
          <div>
            <DataDictFinder dictType="contractSignStatus" :dictValue="item.contractSignStatus" />
          </div>
        </a-descriptions-item>

        <a-descriptions-item :span="2">
          <div slot="label" class="center">阶段</div>
          <div>
            <DataDictFinder dictType="phase" :dictValue="item.phase" />
          </div>
        </a-descriptions-item>

        <a-descriptions-item :span="2">
          <div slot="label" class="center">附件</div>
          <div>
            <div v-if="item.attachments">
              <div class="link" v-for="element in item.attachments.split(',')" :key="element">
                <a target="_blank" download :href="element" v-if="element.indexOf('http') !== -1">{{
                                  uploadFileNameFormatter(element) }}</a>
                <a v-else @click.prevent="clickLink(element)">{{
                                  fileNameFormatter(element)
                                  }}</a>
              </div>
            </div>
          </div>
        </a-descriptions-item>

        <a-descriptions-item :span="2">
          <div slot="label" class="center">工程概况</div>
          <div>
            {{ item.scale }}
          </div>
        </a-descriptions-item>
      </a-descriptions>

      <div class="title">合同规模</div>
      <ScaleList :list="item.contractScaleList" />

      <Padding />

      <div class="title">合同收款项</div>
      <a-table v-if="item.planReceiptList" bordered :data-source="item.planReceiptList" :pagination="false">
        <a-table-column title="收款进度编号" data-index="code" />
        <a-table-column title="收款进度名称" data-index="name" />
        <a-table-column title="负责人" data-index="chargerName" />
        <a-table-column title="收款项金额（元）" data-index="planReceipt" align="right">
          <template slot-scope="planReceipt">
            {{ moneyFilter(planReceipt) }}
          </template>
        </a-table-column>
        <a-table-column title="计划年月" data-index="planReceiptYearmonth" align="center">
          <template slot-scope="planReceiptYearmonth">
            {{ dateFormatter(planReceiptYearmonth) }}
          </template>
        </a-table-column>
      </a-table>
    </div>
    <ExtraInfo />
  </a-card>
</template>


<script>
import moment from "moment";
import ExtraInfo from "./contract-extra-info";
import { encode } from "js-base64";
import { fetchContract } from "@/api/share";
import ScaleList from "../../contract/components/scale-list";

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    ExtraInfo,
    ScaleList,
  },

  data() {
    return {
      loading: false,
      contractList: [],
    };
  },

  mounted() {
    this.loading = true;
    fetchContract({
      idList: this.list,
    })
      .then((res) => {
        this.contractList = Array.isArray(res) ? res : [];
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    clickLink(path) {
      const num = path.split("_")[0];
      const time = moment().format("YYYY-MM-DD HH:mm:ss");
      const auth = encode(num + "_" + time);
      const url = `http://218.94.135.30:8025/FileStore/Download.aspx?auth=${auth}`;

       window.open(url)
      const that = this;
      this.$info({
        title: '如果浏览器无法下载，请手动复制下方文件链接',
        content: url,
        okText: '复制',
        onOk() {
          that.$copy(url)
        }
      })

      // const newWindow = window.open("_blank");
      // newWindow.location = url;

      // const that = this;
      // navigator.clipboard.writeText(url).then(
      //   function () {
      //     that.$success({
      //       title: "提示",
      //       content: "复制链接成功！",
      //     });
      //   },
      //   function () {
      //     that.$error({
      //       title: "提示",
      //       content: "复制链接失败！",
      //     });
      //   }
      // );

      // window.open(url);
    },

    // 金慧的文件名称格式化
    fileNameFormatter(path) {
      const arr = path.split("_");
      if (arr.length >= 2) {
        return arr[1];
      } else {
        return path;
      }
    },
    // 自己上传的文件名称格式化
    uploadFileNameFormatter(path) {
      const arr = path.split("_");
      if (arr.length > 0) {
        return arr[arr.length - 1];
      } else {
        return path;
      }
    },

    moneyFilter(money) {
      let startIndex = 0;
      if (parseFloat(money) < 0) {
        startIndex = 1;
      }

      let str = String(money)
        .substring(startIndex)
        .split("")
        .reverse()
        .join("");

      let val = str
        .split("")
        .map((item, index) => {
          if (index + 1 === str.length) {
            return item;
          }
          if ((index + 1) % 3 === 0) {
            return item + ",";
          } else {
            return item;
          }
        })
        .join("")
        .split(",")
        .map((item) => {
          return item.split("").reverse().join("");
        })
        .reverse()
        .join(",");

      if (startIndex === 1) {
        return "-" + val;
      } else {
        return val;
      }
    },

    dateFormatter(date) {
      const dateStr = String(date);
      if (dateStr.length === 6) {
        return dateStr.substring(0, 4) + "-" + dateStr.substring(4);
      } else {
        return date;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  margin-bottom: 24px;
}

.header>span:first-child {
  font-weight: bold;
  color: #1890ff;
  font-size: 22px;
}

.header>span:last-child {
  font-size: 1.2em;
}

.title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 12px;
}
</style>
